import {
    CHANGE_LOCALE,
    CHANGE_STORE,
    CHANGE_FEATURE_TOGGLE,
} from '../actions';


export const changeLocale = (locale) => {
    localStorage.setItem('currentLanguage', locale);
    return (
        {
            type: CHANGE_LOCALE,
            payload: locale
        }
    )
}

export const changeStore = (store) => {
    return (
        {
            type: CHANGE_STORE,
            payload: store
        }
    )
}

export const changeFeatureToggle = (featureToggle) => {
    return (
        {
            type: CHANGE_FEATURE_TOGGLE,
            payload: featureToggle
        }
    )
}
